<template>
  <AnimatedContainer :ready="data.events.length > 0">
    <ContentSection class="bg-amber-100">
      <Container indent="container-tight">
        <h2 v-if="data.heading" class="mb-8 text-lg font-semibold">
          {{ data.heading }}
        </h2>
        <div>
          <div
            v-for="(card, index) in computedEvents"
            :key="index"
            class="fade-in"
            :class="`${card.color_theme?.value || 'blue'}-el`"
          >
            <Component
              :is="
                card.event_card_layout.value === 'as_accordion'
                  ? 'EventCardAccordion'
                  : 'EventCard'
              "
              :card="card"
              class="mb-6"
            />
          </div>
        </div>
      </Container>
    </ContentSection>
  </AnimatedContainer>
</template>

<script setup>
const props = defineProps({
  data: { type: Object, required: true },
})

// VARIABLES
const events = ref([])

// COMPUTED
const computedEvents = computed(() => {
  if (props.data.show_all_events) {
    return events.value
  }
  return props.data.events
})

// WATCHERS
watch(
  () => props.data,
  (newValue) => {
    if (newValue.show_all_events) {
      fetchEvents()
    }
  },
  { deep: true, immediate: true }
)

// FUNCTIONS
async function fetchEvents() {
  const response = await useAsyncGql({
    operation: 'Events',
  })

  events.value = response.data?.value?.entries?.events
}
</script>
